import { css } from '@emotion/core';
import theme from './theme';
import { rem } from './utils';
import normalize from './normalize.css';

const {
  color,
  scaffolding,
  font: { inter, size, color: fontColor },
} = theme;

export const scaffoldingStyles = css`
  ${normalize};
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    font-size: ${rem(size.default)};
    font-family: ${inter};
    font-weight: 400;
    line-height: 1.7;
    color: ${scaffolding.color};
    background-color: ${scaffolding.background};
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
    font-variant-ligatures: common-ligatures, contextual;
    font-variant-numeric: oldstyle-nums, proportional-nums;
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    letter-spacing: -0.011em;
    word-wrap: break-word;
    overflow-x: hidden;
    margin: 0;
    -webkit-tap-highlight-color: ${color.grey900Transparent15};
  }

  ::-moz-selection {
    background: ${scaffolding.selection};
    color: ${scaffolding.selectionColor};
  }

  ::selection {
    background: ${scaffolding.selection};
    color: ${scaffolding.selectionColor};
  }

  p,
  li,
  a {
    font-size: ${rem(size.medium)};
  }

  p,
  ul {
    margin: 0 0 ${rem(25)};
  }

  a {
    color: ${fontColor.text};
    &:hover {
      text-decoration: none;
    }
    &:active {
      color: ${fontColor.neutral};
    }
  }

  strong {
    font-weight: 700;
  }

  code {
    background-color: ${color.blueGrey50};
    border-radius: 2px;
    padding: 3px;
  }

  kbd {
    background-color: ${color.blueGrey50};
    border-radius: 2px;
    border: 1px solid ${color.blueGrey100};
    box-shadow: 1px 1px 0 1px ${color.blueGrey100}, -1px 1px 0 1px ${color.blueGrey100};
    padding: 2px 7px;
  }

  pre {
    margin-top: 0;
    word-break: normal;
    hyphens: none;
    overflow: scroll;
  }

  pre.shiki {
    background-color: ${color.blueGrey50} !important;
    padding: 15px 20px 12px 22px;
    border-radius: 2px;
    margin-bottom: ${rem(25)};
  }

  pre code {
    white-space: pre;
    overflow-x: scroll;
    max-width: 100%;
    min-width: 100px;
    -webkit-overflow-scrolling: touch;
  }

  .overflowYHidden {
    overflow-y: hidden;
  }

  iframe {
    margin: 0 0 ${rem(25)};
  }
`;
