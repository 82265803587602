import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Page from '../templates/Page';
import { SEO, TopNavigation, Heading, TextAccent, Link, ArrowExternal } from '../components';

const wrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin: 75px 0;
  max-width: 550px;
  ${breakpoint.fromDesktop} {
    margin: 150px 0;
  }
  @media screen and (min-width: 112.5em) {
    margin: 250px 0;
  }
`;
const Wrapper = styled('section')(wrapperStyles);

/**
 * 404 page
 */
const NotFoundPage = () => (
  <Page>
    <SEO title="Danijel Grabež" />
    <TopNavigation />
    <Wrapper>
      <Heading element="h1" colorKind="brand">
        404 ¯\_(ツ)_/¯
      </Heading>
      <TextAccent withSpacing>
        The page you are looking for does not exist. <Link to="/">Back to Homepage</Link>
      </TextAccent>
      <TextAccent>
        <strong>Did you follow a link from here?</strong>
        <br />
        If you reached this page from social media, or another part of my website, please{' '}
        <Link to="mailto:danijel.grabez@gmail.com?subject=I%20found%20a%20bug%20on%20your%20website">
          let me know
          <ArrowExternal />
        </Link>{' '}
        and I will update the content accordingly.
      </TextAccent>
    </Wrapper>
  </Page>
);

export default NotFoundPage;
